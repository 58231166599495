// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-blog-blog-01-index-jsx": () => import("./../../../src/pages/blog/Blog01/index.jsx" /* webpackChunkName: "component---src-pages-blog-blog-01-index-jsx" */),
  "component---src-pages-blog-blog-02-index-jsx": () => import("./../../../src/pages/blog/Blog02/index.jsx" /* webpackChunkName: "component---src-pages-blog-blog-02-index-jsx" */),
  "component---src-pages-blog-blog-03-index-jsx": () => import("./../../../src/pages/blog/Blog03/index.jsx" /* webpackChunkName: "component---src-pages-blog-blog-03-index-jsx" */),
  "component---src-pages-blog-blog-04-index-jsx": () => import("./../../../src/pages/blog/Blog04/index.jsx" /* webpackChunkName: "component---src-pages-blog-blog-04-index-jsx" */),
  "component---src-pages-blog-blog-05-index-jsx": () => import("./../../../src/pages/blog/Blog05/index.jsx" /* webpackChunkName: "component---src-pages-blog-blog-05-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-floki-d-2-c-app-index-jsx": () => import("./../../../src/pages/floki-d2c-app/index.jsx" /* webpackChunkName: "component---src-pages-floki-d-2-c-app-index-jsx" */),
  "component---src-pages-floki-marketplace-app-index-jsx": () => import("./../../../src/pages/floki-marketplace-app/index.jsx" /* webpackChunkName: "component---src-pages-floki-marketplace-app-index-jsx" */),
  "component---src-pages-floki-retail-app-b-2-c-index-jsx": () => import("./../../../src/pages/floki-retail-app-b2c/index.jsx" /* webpackChunkName: "component---src-pages-floki-retail-app-b-2-c-index-jsx" */),
  "component---src-pages-floki-supermarket-app-index-jsx": () => import("./../../../src/pages/floki-supermarket-app/index.jsx" /* webpackChunkName: "component---src-pages-floki-supermarket-app-index-jsx" */),
  "component---src-pages-floki-wholesale-app-b-2-b-index-jsx": () => import("./../../../src/pages/floki-wholesale-app-b2b/index.jsx" /* webpackChunkName: "component---src-pages-floki-wholesale-app-b-2-b-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-testimonials-index-jsx": () => import("./../../../src/pages/Testimonials/index.jsx" /* webpackChunkName: "component---src-pages-testimonials-index-jsx" */),
  "component---src-pages-testpage-index-jsx": () => import("./../../../src/pages/testpage/index.jsx" /* webpackChunkName: "component---src-pages-testpage-index-jsx" */)
}

